<template>
  <CContainer fluid>
    <CTabs variant="tabs" :active-tab="0" @update:activeTab="onActiveTab">
      <CTab title="Info">
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <CRow class="justify-content-between px-3">
                  <b>User info</b>
                  <div class="d-flex">
                    <CButton
                      @click="isShowEditConsumerInfoModal = true"
                      size="sm"
                      color="primary"
                      class="mr-2"
                      >Edit</CButton
                    >
                    <CButton
                      @click="changeStatusModal = true"
                      size="sm"
                      color="warning"
                      class="mr-2"
                      >Change Status</CButton
                    >

                    <CButton
                      size="sm"
                      color="dark"
                      class="mr-2"
                      @click="onFetchNoteInternal"
                      >Internal Notes</CButton
                    >
                  </div>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <dd class="col-sm-12">
                    <CImg
                      v-bind:src="user.photo_url || 'img/no-avatar.png'"
                      width="100px"
                      height="100px"
                      class="mb-2"
                      shape="rounded"
                    />
                  </dd>

                  <dt class="col-sm-2">ID</dt>
                  <dd class="col-sm-10">{{ user.id }}</dd>
                  <dt class="col-sm-2">Name</dt>
                  <dd class="col-sm-10">{{ user.name ? user.name : '-' }}</dd>
                  <dt class="col-sm-2">Email</dt>
                  <dd class="col-sm-10">{{ user.email }}</dd>
                  <dt class="col-sm-2">Phone</dt>
                  <dd class="col-sm-10">
                    {{ user.phone_number ? user.phone_number : '-' }}
                  </dd>
                  <dt class="col-sm-2">Role</dt>
                  <dd class="col-sm-10">{{ user.role }}</dd>
                  <dt class="col-sm-2">Status</dt>
                  <dd class="col-sm-10">
                    <CBadge :color="getUserBadge(user.status)">
                      {{ user.status }}
                    </CBadge>
                  </dd>
                  <dt class="col-sm-2">LastLogin</dt>
                  <dd class="col-sm-10">
                    {{ moment(user.last_login).fromNow() }}
                  </dd>

                  <dt class="col-sm-2">CreatedAt</dt>
                  <dd class="col-sm-10">
                    {{ user.created_at | moment(datetimeFormat) }}
                  </dd>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <CRow class="justify-content-between px-3">
                  <b>User setting</b>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <dt class="col-sm-2">Owner Provider ID</dt>
                  <dd class="col-sm-10">
                    <span
                      v-if="
                        user.user_setting &&
                        user.user_setting?.owner_provider_id
                      "
                    >
                      <CLink
                        :href="'#/sp/' + user.user_setting?.owner_provider_id"
                        target="_blank"
                      >
                        {{ user.user_setting?.owner_provider_id }}
                      </CLink>
                    </span>
                    <span v-else>-</span>
                  </dd>
                  <dt class="col-sm-2">Stripe Customer</dt>
                  <dd class="col-sm-10">
                    <span
                      v-if="
                        user.user_setting &&
                        user.user_setting?.stripe_customer_id
                      "
                    >
                      <CLink
                        :href="
                          env.stripeURL +
                          '/customers/' +
                          user.user_setting?.stripe_customer_id
                        "
                        target="_blank"
                      >
                        {{ user.user_setting?.stripe_customer_id }}
                      </CLink>
                    </span>
                    <span v-else>-</span>
                  </dd>
                  <dt class="col-sm-2">Default location ID</dt>
                  <dd class="col-sm-10">
                    {{ user.user_setting?.default_location_id }}
                  </dd>
                  <dt class="col-sm-2">Default payment method ID</dt>
                  <dd class="col-sm-10">
                    {{ user.user_setting?.default_payment_method }}
                  </dd>
                </CRow>
                <hr />
                <CRow>
                  <dt class="col-sm-2">BadgeCount</dt>
                  <dd class="col-sm-10">
                    {{ this.countInfo?.badge_count }}
                  </dd>
                  <dt class="col-sm-2">HighlightCount</dt>
                  <dd class="col-sm-10">
                    {{ this.countInfo?.highlight_of_booking }}
                  </dd>
                  <dt class="col-sm-2">UnreadRoom</dt>
                  <dd class="col-sm-10">
                    {{ this.countInfo?.unread_room_count }}
                  </dd>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CTab>
      <CTab title="Invoices">
        <CRow>
          <CCol>
            <InvoiceListComponent
              title="Invoices"
              :items="invoices"
              :fields="invoicesFields"
            />
          </CCol>
        </CRow>
      </CTab>
    </CTabs>

    <CModal title="Change status" :show.sync="changeStatusModal" size="lg">
      <CCol sm="4">
        <CSelect
          label="Status"
          :value.sync="statusSelected"
          :options="options"
        />
      </CCol>
      <template #footer>
        <CButton @click="changeStatusModal = false" color="secondary"
          >Cancel</CButton
        >
        <CButton @click="onChangeStatus" color="primary">Save</CButton>
      </template>
    </CModal>

    <EditConsumerInfoModal
      :showModal="isShowEditConsumerInfoModal"
      :consumerData="{ ...user }"
      @close-modal="isShowEditConsumerInfoModal = false"
      @save="onUpdateConsumer"
    />

    <ViewNoteInternalComponent
      title="Notes"
      :isShow="showNoteInternalList"
      :noteData="noteData"
      @close="showNoteInternalList = false"
    />
  </CContainer>
</template>

<script>
import { ACTION_FETCH_USER, ACTION_FETCH_INVOICES } from '@/store/actions.type';
import { UserBadge } from '@/utils/utils';
import UserProxy from '@/proxies/user.proxy';
import ConsumerProxy from '@/proxies/consumer.proxy';

import env from '@/constants/env';
import { Common } from '@/constants';
import EditConsumerInfoModal from '@/components/modals/EditConsumerInfoModal';
import { MUTATION_SET_USER } from '@/store/mutations.type';
import AdminProxy from '@/proxies/admin.proxy';
import ViewNoteInternalComponent from '@/components/ViewNoteInternalComponent';
import InvoiceListComponent from '@/components/InvoiceListComponent';
import { mapGetters } from 'vuex';

export default {
  name: 'Consumer',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes('users');
    });
  },
  components: {
    EditConsumerInfoModal,
    ViewNoteInternalComponent,
    InvoiceListComponent,
  },
  data() {
    return {
      isShowEditConsumerInfoModal: false,
      env,
      datetimeFormat: Common.datetimeFormat,
      id: undefined,
      usersOpened: null,
      changeStatusModal: false,
      statusSelected: 'active',
      options: [
        { value: 'active', label: 'Active' },
        // { value: 'inactive', label: 'Inactive' },
        { value: 'banned', label: 'Banned' },
      ],
      showNoteInternalList: false,
      noteData: {
        notes: [],
        id: undefined,
        type: 'consumer',
      },
      invoicesFields: [
        {
          key: 'invoice_no',
          label: 'InvoiceNo',
        },
        {
          key: 'booking_no',
          label: 'BookingNo',
        },
        {
          key: 'issued_by',
          label: 'IssuedBy',
        },
        {
          key: 'invoice_type',
          label: 'Type',
        },
        {
          key: 'pdf_file',
          label: 'PDF',
        },
        {
          key: 'created_at',
          label: 'CreatedDate',
        },
      ],
      countInfo: {},
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
    this.noteData.id = this.id;

    this.$store.dispatch(ACTION_FETCH_USER, this.id);
    this.$store.dispatch(ACTION_FETCH_INVOICES, { consumer_id: this.id });

    ConsumerProxy.adminGetConsumerCountInfo(this.id).then(({ data }) => {
      this.countInfo = data;
    });
  },
  computed: {
    ...mapGetters({
      user: 'user',
      invoices: 'invoices',
    }),
  },
  methods: {
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: '/users' });
    },
    getUserBadge(status) {
      return UserBadge(status);
    },
    onChangeStatus() {
      const data = { status: this.statusSelected };
      UserProxy.changeStatus(this.id, data).then(() => {
        this.user.status = this.statusSelected;
        this.changeStatusModal = false;
      });
    },
    onUpdateConsumer(consumerData) {
      let data = { name: consumerData.name };
      UserProxy.updateUser(this.id, data).then(() => {
        this.isShowEditConsumerInfoModal = false;
        this.$toast.success(`Update consumer  successfully`, {
          duration: 5000,
        });

        this.$store.commit(MUTATION_SET_USER, {
          ...this.user,
          ...data,
        });
      });
    },
    onFetchNoteInternal() {
      let query = { type: this.noteData.type, id: parseInt(this.id) };
      AdminProxy.fetchNoteInternal(query).then(({ data }) => {
        this.showNoteInternalList = true;
        this.noteData.notes = data;
      });
    },
    onActiveTab(index) {
      console.log('index', index);
    },
  },
};
</script>
