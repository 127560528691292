<template>
  <div id="invoiceListComponent">
    <CCard>
      <CCardHeader>
        <b>{{ title }} </b>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :items-per-page="25"
          :pagination="{ doubleArrows: false, align: 'center' }"
          striped
          border
          sorter
          :items="items"
          :fields="fields"
        >
          <td slot="pdf_file" slot-scope="{ item }">
            <CLink :href="item.pdf_file" target="_blank">
              {{ item.pdf_file }}
            </CLink>
          </td>
          <td slot="booking_no" slot-scope="{ item }">
            <span v-if="item.booking_id">
              <CLink :href="'#/bookings/' + item.booking_id">
                {{ item.booking_no ? item.booking_no : '-' }}
              </CLink>
            </span>
            <span v-else>-</span>
          </td>
          <td slot="created_at" slot-scope="{ item }">
            {{ item.created_at | moment(datetimeFormat) }}
          </td>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { Common } from '@/constants';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      datetimeFormat: Common.datetimeFormat,
      dateFormat: Common.dateFormat,
    };
  },
};
</script>
