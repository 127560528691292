<template>
  <div id="viewNoteInternalComponent">
    <CSidebar :show="isShow" shadow aside size="xl" colorScheme="light">
      <div class="modal-header">
        <h4>{{ title }}</h4>
        <div class="mr-2">
          <CButton
            class="mx-4"
            size="sm"
            color="primary"
            @click="isShowAddNote = true"
          >
            <CIcon name="cil-plus" />
          </CButton>
          <CButtonClose @click="$emit('close')" />
        </div>
      </div>
      <div class="list-content p-3">
        <template v-for="item in noteData.notes">
          <div :key="item.id">
            <span
              ><span
                :style="{
                  color: utils.PickColorFromString(item.created_by_user.name),
                }"
                ><b>{{ item.created_by_user.name }} </b></span
              >- {{ item.created_at | moment(datetimeFormat) }}</span
            >
            <p style="font-size: medium">
              {{ item.note }}
            </p>
          </div>
        </template>
      </div>
    </CSidebar>
    <CModal title="Add note" :show.sync="isShowAddNote" size="lg">
      <CForm>
        <CTextarea :value.sync="noted" placeholder="Noted..." rows="5" />
      </CForm>
      <template #footer>
        <CButton @click="isShowAddNote = false" color="secondary"
          >Cancel</CButton
        >
        <CButton @click="onAddNote" color="primary">Save</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { Common } from '@/constants';
import AdminProxy from '@/proxies/admin.proxy';

export default {
  name: 'ViewNoteInternalComponent',
  props: {
    title: {
      type: String,
      default: '',
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    noteData: {
      type: Object,
    },
  },
  data() {
    return {
      datetimeFormat: Common.datetimeFormat,
      isShowAddNote: false,
      noted: '',
    };
  },
  methods: {
    onAddNote() {
      AdminProxy.addNote({
        id: parseInt(this.noteData.id),
        type: this.noteData.type,
        note: this.noted,
      }).then(({ data }) => {
        this.noted = '';
        this.isShowAddNote = false;
        this.noteData.notes = [data, ...this.noteData.notes];
      });
    },
  },
};
</script>

<style lang="scss">
.list-content {
  max-height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
</style>
