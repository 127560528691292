<template>
  <div id="editConsumerInfoModal">
    <CModal
      :show.sync="showModal"
      size="lg"
      :centered="true"
      :closeOnBackdrop="false"
    >
      <CForm>
        <CInput
          label="Name"
          horizontal
          placeholder="Enter name..."
          v-model="consumerData.name"
          required
          was-validated
        />
      </CForm>
      <template #header>
        <h5 class="modal-title">Edit Consumer</h5>
        <CButtonClose @click="$emit('close-modal')" />
      </template>
      <template #footer>
        <CButton @click="$emit('close-modal')" color="secondary"
          >Cancel</CButton
        >
        <CButton @click="onUpdateData" color="primary">Save</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    consumerData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    onUpdateData() {
      this.$emit('save', this.consumerData);
    },
  },
  name: 'EditConsumerInfoModal',
};
</script>
