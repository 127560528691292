import BaseProxy from './base.proxy';

class AdminProxy extends BaseProxy {
  dashboardMetrics() {
    return this.get('/admin/dashboard/metrics');
  }
  getFinances(query) {
    let queryString = new URLSearchParams(query).toString();
    return this.get(`/admin/finance?${queryString}`);
  }
  fetchNoteInternal(query) {
    let queryString = new URLSearchParams(query).toString();
    return this.get(`/admin/note-internal?${queryString}`);
  }
  addNote(data) {
    return this.post(`/admin/note-internal`, data);
  }

  updateUserInternal(id, data) {
    return this.put(`/admin/user-internal/${id}`, data);
  }
}
export default new AdminProxy();
